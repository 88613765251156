<template>
    <component :is="listComponent" :pageName="pageName" :pageModel="pageModel">
        <slot />
    </component>
</template>

<script>
import MeetingList from './components/MeetingList'
export default {
    name: "MeetingIndex",
    components: {
        MeetingList
    },
    props: {
        pageName: {
            type: String,
            default: 'page_list_meetings.PlannedMeetingModel'
        },
        pageModel: {
            type: String,
            default: 'meetings.PlannedMeetingModel'
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        listComponent() {
            if(this.isMobile) {
                return () => import('./components/MeetingListMobile.vue')
            } else {
                return () => import('./components/MeetingList.vue')
            }
        }
    }
}
</script>